<template>
    <div class="notices">
        <div class="Notices-T">
            <div class="NoticesL">
                <Breadcrumb></Breadcrumb>
            </div>
            <div class="NoticesR" v-permission="'Notice:Add'">
                <el-button type="primary" @click="newNotices">新增公告</el-button>
            </div>
        </div> 
        <div class="Notices-B">
            <el-table :data="tableData" style="width: 100%;margin-bottom: 20px;" border>
                <template slot="empty">
                    <noData></noData>
                </template>
                <el-table-column label="标题" width="200" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        {{ scope.row.title }}
                    </template>
                </el-table-column>
                <el-table-column label="内容" min-width="400" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        {{ scope.row.content }}
                    </template>
                </el-table-column>
                <el-table-column label="是否可见" width="100" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.state "
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            :active-value="0"
                            :inactive-value="1"
                            :disabled="scope.row.disabled"
                            @change="changeSwitch(scope.row)"
                            >
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" min-width="400">
                    <template slot-scope="scope">
                        <el-button v-permission="'Notice:Update'" size="small" @click="editFun(scope.row)">编辑</el-button>
                        <el-button v-permission="'Notice:Del'" size="small" @click="delFun(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
                :current-page="from.page" :page-size="from.page_z" layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>
        <newNotices :titles="titles" ref="noticesRef" @closefn="noticeListApi()"></newNotices>
    </div>
</template>

<script>
import { noticeList,noticedel,noticeup } from "@/api/notices.js";
import newNotices from "./modules/newNotices.vue";
export default {
    name: "",
    components: { newNotices },
    data() {
        return {
            tableData: [],
            titles: '新增',
            from: {
                page: 1,
                page_z: 10
            },
            total: 0
        }
    },
    mounted() {
        this.noticeListApi()
    },
    methods: {
        async changeSwitch(row){
            const index = this.tableData.findIndex(item=>item.id==row.id)
            let {state,bulletinId:bulletinid} = row
            // this.tableData[index].disabled = true
            const result = await noticeup({state,bulletinid})
            if(result.data.Code==200){
                this.$message.success(result.data.Msg)
                this.noticeListApi()
            }else{
                setTimeout(() => {
                    this.tableData[index].state = this.tableData[index].state==0?1:0
                    this.tableData[index].disabled = false
                }, 500);
            }
        },
        handleCurrentChange(pages) {
            this.from.page = pages
            this.noticeListApi()
        },
        handleSizeChange(pages) {
            this.from.page_z = pages
            this.noticeListApi()
        },
        async noticeListApi() {
            const result = await noticeList(this.from)
            if (result.data.Code == 200) {
                let arr = result.data.Data.Data || []
                arr.forEach(item =>  item.disabled = false);
                this.tableData = arr 
                this.total = result.data.Data.Num
            }
        },
        delFun(data) {
            this.$confirm('此操作删除通知消息, 是否删除?', '删除', {
                confirmButtonText: '立即删除',
                cancelButtonText: '取消',
                type: 'error',
                center: true
            }).then(async() => {
                const result = await noticedel({bulletin_id:data.bulletinId})
                if(result.data.Code==200){
                    this.$message.success(result.data.Msg);
                    this.noticeListApi()
                }
            }).catch(() => {
                this.$message.info('已取消删除');
            });
        },
        editFun(data) {
            this.titles = '编辑'
            this.$refs.noticesRef.showDrawer()
            const { title, content,bulletinId:bulletinid } = data
            this.$refs.noticesRef.form = { title, content,bulletinid }
        },
        newNotices() {
            this.titles = '新增'
            this.$refs.noticesRef.showDrawer()
            this.$refs.noticesRef.form = {
                title: '',
                content: '',
            }
        },
    }
}
</script>
<style lang="scss">
.Notices-T {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(#fff, 1);
    height: 70px;
    padding: 15px 10px;
    box-shadow: 5px 5px 10px -10px #000;

    .NoticesL {
        display: flex;
        align-items: center;
    }

    .NoticesR {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}

.Notices-B {
    background: rgba(#fff, 1);
    margin: 10px;
    padding: 20px 30px;
    color: #000;
}
</style>